import {createContext, ReactNode, useContext, useState} from "react"

export type ToolbarContent = {
  action?: "menu" | "back"
  backUrl?: string
  content: ReactNode | null
}

const ToolbarContext = createContext<{
  toolbarContentMap: Map<string, ToolbarContent>,
  registerToolbarContent: (
    path: string,
    content: ToolbarContent | null
  ) => void,
} | null>(null);

export const ToolbarContextProvider = ({children}: { children?: ReactNode }) => {

  const [toolbarContentMap, setToolbarContentMap] = useState<Map<string, ToolbarContent>>(new Map())

  return (
    <ToolbarContext.Provider
      value={{
        toolbarContentMap,
        registerToolbarContent: (path, content) => {
          setToolbarContentMap((prev) => {
            const newMap = new Map(prev)
            if (content) {
              newMap.set(path, content)
            } else {
              newMap.delete(path)
            }
            return newMap
          })
        }
      }}
    >
      {children}
    </ToolbarContext.Provider>
  )
}
export const useToolbarContext = () => {
  const context = useContext(ToolbarContext)
  if (!context) throw new Error("You can only use ToolbarContext within it's provider")
  return context
}